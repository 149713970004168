var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "base-template page-wrapper" },
    [
      _c("router-view"),
      _c("OrganismSidebar", [
        _c("div", { staticClass: "menu" }, [
          _vm.staticPages.length > 1
            ? _c(
                "div",
                [
                  _c("div", { staticClass: "subtitle" }, [_vm._v("Cities")]),
                  _vm._l(_vm.staticPages, function (page, i) {
                    return _c("div", { key: i }, [
                      _c(
                        "span",
                        {
                          staticClass: "sidebar-link",
                          on: {
                            click: function ($event) {
                              return _vm.handleProjectChange(page)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(page.menuLabel || page.name) +
                              "\n          "
                          ),
                        ]
                      ),
                    ])
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm.projects.length > 1
            ? _c("div", { staticClass: "separator" })
            : _vm._e(),
          _c(
            "div",
            [
              _vm.customLinks.length > 0
                ? _c(
                    "div",
                    _vm._l(_vm.customLinks, function (customLink, i) {
                      return _c("div", { key: i }, [
                        _c(
                          "a",
                          {
                            staticClass: "sidebar-link",
                            attrs: {
                              href: "" + customLink.slug,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(customLink.name) +
                                "\n            "
                            ),
                          ]
                        ),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
              _vm._l(_vm.sections, function (section, i) {
                return _c("div", { key: i }, [
                  section.enabled
                    ? _c(
                        "a",
                        {
                          staticClass: "sidebar-link",
                          attrs: { href: "#" + section.hashAnchor },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(section.menuLabel) +
                              "\n          "
                          ),
                        ]
                      )
                    : _vm._e(),
                ])
              }),
            ],
            2
          ),
        ]),
        _c("div", { staticClass: "social-links" }, [
          _vm.instagramUrl
            ? _c("a", { attrs: { href: _vm.instagramUrl } }, [
                _vm._v("Instagram"),
              ])
            : _vm._e(),
          _vm.linkedinUrl
            ? _c("a", { attrs: { href: _vm.linkedinUrl } }, [
                _vm._v("Linkedin"),
              ])
            : _vm._e(),
          _vm.facebookUrl
            ? _c("a", { attrs: { href: _vm.facebookUrl } }, [
                _vm._v("Facebook"),
              ])
            : _vm._e(),
        ]),
      ]),
      _c("OrganismMenuBar"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }